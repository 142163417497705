// Webpack Imports
import * as bootstrap from 'bootstrap';
(function () {
    'use strict';

    // Focus input if Searchform is empty
    [].forEach.call(document.querySelectorAll('.search-form'), (el) => {
        el.addEventListener('submit', function (e) {
            var search = el.querySelector('input');
            if (search.value.length < 1) {
                e.preventDefault();
                search.focus();
            }
        });
    });

    // Initialize Popovers: https://getbootstrap.com/docs/5.0/components/popovers
    var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
    var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
        return new bootstrap.Popover(popoverTriggerEl, {
            trigger: 'focus',
        });
    });
})();

jQuery(document).ready(function ($) {
    $('.slides').slick({
        slidesToShow: 3,
        arrows: true,
        prevArrow: '<button type="button" class="slick-prev" title="Strzałka"><svg width="82" height="82" viewBox="0 0 82 82" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M24.5161 43.197L38.2492 56.93L35.143 60.0363L16.1073 41.0006L35.143 21.9648L38.2492 25.0711L24.5161 38.8041L64.4287 38.8041L64.4287 43.197L24.5161 43.197Z" fill="black"/></svg></button>',
        nextArrow: '<button type="button" class="slick-next" title="Strzałka"><svg width="82" height="82" viewBox="0 0 82 82" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M57.4839 38.803L43.7508 25.0699L46.857 21.9637L65.8927 40.9994L46.857 60.0352L43.7508 56.9289L57.4839 43.1959L17.5713 43.1959L17.5713 38.803L57.4839 38.803Z" fill="black"/></svg></button>',
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    adaptiveHeight: true,
                },
            },
        ],
    });
    var urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('szkolenie_id')) {
        var szkolenieId = urlParams.get('szkolenie_id');
        $('select[name="input_6"] option[value="' + szkolenieId + '"]').prop('selected', true);
    }

    if ($(window).width() < 768) {
        $('.accordion-section').each(function () {
            $(this)
                .find('ul, ol')
                .each(function () {
                    if ($(this).find('li').length > 2) {
                        $(this).find('li:gt(1)').hide().addClass('toggleable');
                        $(this).append(
                            '<button class="showMore"><span>Rozwiń</span><svg width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.57067 0.587363L6.57015 12.4534L10.9645 8.05912C11.0746 7.94903 11.2239 7.88718 11.3796 7.88718C11.5353 7.88718 11.6846 7.94903 11.7947 8.05912C11.9048 8.16921 11.9666 8.31852 11.9666 8.47421C11.9666 8.62991 11.9048 8.77922 11.7947 8.88931L6.3984 14.2856C6.28831 14.3957 6.139 14.4575 5.9833 14.4575C5.82761 14.4575 5.6783 14.3957 5.56821 14.2856L0.171939 8.88931C0.0618482 8.77922 3.95127e-08 8.62991 0 8.47421C6.32203e-08 8.31852 0.0618482 8.16921 0.171939 8.05912C0.28203 7.94903 0.431345 7.88718 0.587036 7.88718C0.742728 7.88718 0.892043 7.94903 1.00213 8.05912L5.39646 12.4534L5.39594 0.587363C5.39594 0.51023 5.41113 0.433851 5.44065 0.362589C5.47017 0.291327 5.51343 0.226577 5.56797 0.172035C5.62252 0.117493 5.68727 0.0742279 5.75853 0.0447107C5.82979 0.0151928 5.90617 -6.84884e-08 5.9833 0C6.06044 6.32205e-08 6.13681 0.0151929 6.20808 0.0447104C6.27934 0.0742285 6.34409 0.117493 6.39863 0.172035C6.45317 0.226577 6.49644 0.291327 6.52596 0.362589C6.55547 0.433851 6.57067 0.51023 6.57067 0.587363Z" fill="black"/></svg></button>'
                        );
                    }
                });
        });

        $('.showMore').on('click', function () {
            if ($(this).hasClass('expanded')) {
                $(this).siblings('.toggleable').slideUp();
                $(this).removeClass('expanded');
                $(this).find('span').text('Rozwiń');
            } else {
                $(this).siblings('.toggleable').slideDown();
                $(this).addClass('expanded');
                $(this).find('span').text('Zwiń');
            }
        });
    }

    jQuery(document).ready(function ($) {
        if ($('#input_1_6 option:selected').val() !== '') {
            console.log($('#input_1_6 option:selected').val());
            $('#input_1_6').css('color', '#000');
        } else {
            $('#input_1_6').css('color', '#6e7781');
        }

        $('#input_1_6, #input_1_12, #input_1_15').on('focus', function () {
            $(this).find('option:first-child').hide();
        });

        $('#input_1_6, #input_1_12, #input_1_15').on('blur', function () {
            $(this).find('option:first-child').show();
        });

        $('#input_1_6').change(function () {
            $('#input_1_15').css('color', '#6e7781');
        });

        $('#input_1_6, #input_1_12, #input_1_15').change(function () {
            var selectedOption = $(this).find('option:selected').val();
            if (selectedOption === 'gf_placeholder' || selectedOption === 'Wybierz z listy' || selectedOption === 'Wybierz godzinę') {
                $(this).css('color', '#6e7781');
            } else {
                $(this).css('color', '#000');
            }
        });

        if ($('.edge-belt').length) {
            if (window.innerWidth > 992) {
                var heightWithPadding = $('.edge-belt').innerHeight();
                $('.edge-title').css('width', heightWithPadding + 'px');
            }

            $('.edge-title').click(function () {
                $('.edge-belt').toggleClass('opened');
            });

            // zmienna do przechowywania początkowej pozycji przewinięcia
            var initialScroll = $(window).scrollTop();

            $(window).scroll(function () {
                var currentScroll = $(window).scrollTop();

                if (Math.abs(currentScroll - initialScroll) > 200) {
                    $('.edge-belt').removeClass('opened');
                    initialScroll = currentScroll; // aktualizuj pozycję początkową
                }
            });
        }
    });

    // Pobierz aktualny URL
    var currentUrl = window.location.href;

    // Sprawdź, czy URL zawiera "#"
    var hashIndex = currentUrl.indexOf('#');
    if (hashIndex !== -1) {
        // Wyodrębnij fragment z URL (np. "#prawo-gospodarcze")
        var fragment = currentUrl.substring(hashIndex + 1);

        // Odszukaj element z odpowiednim data-title
        var $targetElement = $('[data-title="' + fragment + '"]');

        if ($targetElement.length > 0) {
            // Wywołaj click na elemencie
            $targetElement.click();

            // Przewiń do sekcji #szkolenia z animacją
            var $sectionSzkolenia = $('#szkolenia');
            if ($sectionSzkolenia.length > 0) {
                $('html, body').animate(
                    {
                        scrollTop: $sectionSzkolenia.offset().top,
                    },
                    1000
                ); // 1000 milisekund (1 sekunda) to czas animacji
            }
        }
    }
});

// const event = {
//     title: 'My birthday party',
//     description: 'Be there!',
//     start: '2019-12-29 18:00:00 +0100',
//     duration: [3, 'hour'],
// };

// // Then fetch the link
// google(event); // https://calendar.google.com/calendar/render...
// outlook(event); // https://outlook.live.com/owa/...
// office365(event); // https://outlook.office.com/owa/...
// yahoo(event); // https://calendar.yahoo.com/?v=60&title=...
// ics(event); // standard ICS file based on https://icalendar.org
